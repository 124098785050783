// src/components/BlogDetail.js

import React from 'react';
import { useParams } from 'react-router-dom';
import blogs from './data';
import './Blog.css';
import HeroComponent from '../../components/Hero';
import SEO from '../../components/SEO';

const BlogDetail = () => {
  const { id } = useParams();
  const blog = blogs.find((blog) => blog.id === parseInt(id));

  if (!blog) {
    return <p>Blog not found.</p>;
  }

  return (
    <div className="blog-detail">
        <SEO
        title="Blog - Digital Crew"
        description={blog.metaDescription}
        url="https://digitalcrew.co.in/contact-us"
      />
      <h1>{blog.title}</h1>
      <img src={blog.image} alt={blog.title}  className="blog-detail-image" />
      <div
        className="blog-detail-description"
        dangerouslySetInnerHTML={{ __html: blog.description }}
      />
    </div>
  );
};

export default BlogDetail;
