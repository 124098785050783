import React from 'react';

const BlogCard = ({ id, title, description, image }) => {
  const handleReadMore = () => {
    window.open(`/blog-detail/${id}`, '_blank', 'noopener,noreferrer');
  };

  const truncatedTitle = title.length > 80 ? `${title.substring(0, 80)}...` : title;
  const truncatedDescription = description.length > 30 ? `${description.substring(0, 30)}...` : description;

  return (
    <div className="blog-card">
      <img src={image} alt={title} className="blog-card-image" />
      <h4>{truncatedTitle}</h4>
      <p dangerouslySetInnerHTML={{ __html: truncatedDescription }}></p>
      <button className="read-more-btn" onClick={handleReadMore}>
        Read More
      </button>
    </div>
  );
};

export default BlogCard;
