// src/components/BlogList.js

import React from 'react';
import BlogCard from './Card';
import blogs from './data';
import './Blog.css';
import SEO from '../../components/SEO';
import HeroComponent from '../../components/Hero';

const BlogList = () => {
  return (
    <div className="blog-list">
        <SEO
        title="Blog - Digital Crew"
        description="Contact Digital Crew for top Mobile Apps, Software, Web Designing, and Development Services. To know more about how We can help with your project, contact us Now!"
        url="https://digitalcrew.co.in/blog"
      />
      {/* <HelmetComponent title={"Contact Us - Digital Crew"}  description={"Ask us for any assistance, We're here to help! Contact our customer support for any queries! Feel free to mail us at connect@digitalcrew.co.in"} url="contact-us" />   */}
      <HeroComponent
      bigText={
        "<span> Blog - Digital Crew </span>"
      }
      smallText={
        "Dive into our latest posts to stay updated with industry trends and innovations"
      }      
        page={"aboutus"}
      ></HeroComponent>

      {blogs.map((blog) => (
        <BlogCard
          key={blog.id}
          id={blog.id}
          title={blog.title}
          description={blog.description}
          image={blog.image}
          meta={blog.metaDescription}
        />
      ))}
    </div>
  );
};

export default BlogList;
